//React
import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

//Components
import Layout from '../../components/layout'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const Snippet = (props) => {
  const { data } = props
  console.log('pageData')
  console.log(data)
  const sections =
    data && data.sections && data.sections.edges
      ? data.sections.edges.sort((a, b) =>
          a.node.data.sort > b.node.data.sort ? 1 : -1
        )
      : []
  const articles =
    data && data.articles && data.articles.edges
      ? data.articles.edges.sort((a, b) =>
          a.node.data.sort > b.node.data.sort ? 1 : -1
        )
      : []
  console.log('pageData - sections')
  console.log(sections)
  console.log('pageData - articles')
  console.log(articles)
  return (
    <Layout>
      <Helmet
        title="Storylava - Support"
        meta={[
          {
            name: 'description',
            content: 'Get help and support for using Storylava',
          },
        ]}
      />

      <div class="gradient-callout-algae">
        <div class="container container-page container-about-sub">
          <div class="row">
            <div class="col-lg-10 d-flex flex-column justify-content-center text-center text-lg-left">
              <h1 class="display-2 font-weight-medium white-1">Articles</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="container my-4">
        <div class="row">
          <div class="col-md-12">
            {sections
              ? sections.map((section) => (
                  <div class="pt-3">
                    <Link
                      class={`d-flex support-section-header-list-link bg-${
                        section &&
                        section.node &&
                        section.node.data &&
                        section.node.data.color
                          ? section.node.data.color
                          : ''
                      }-1 p-2 mt-3 round-corners-top white-1 w-100`}
                      to={`/section/${section.node.data.url}`}
                    >
                      {section &&
                      section.node &&
                      section.node.data &&
                      section.node.data.title
                        ? section.node.data.title
                        : ''}
                    </Link>
                    <div
                      class={`border-1-${
                        section &&
                        section.node &&
                        section.node.data &&
                        section.node.data.color
                          ? section.node.data.color
                          : ''
                      }-1 round-corners-bottom p-2`}
                    >
                      {articles
                        ? articles
                            .filter((article) =>
                              article.node.data.categorystring.includes(
                                section.node.data.catid
                              )
                            )
                            .map((article) => (
                              <div class="mt-1">
                                <Link
                                  class={`p-2 round-corners font-weight-light black-3 `}
                                  to={`/article/${article.node.data.url}`}
                                >
                                  {article &&
                                  article.node &&
                                  article.node.data &&
                                  article.node.data.title
                                    ? article.node.data.title
                                    : ''}
                                </Link>
                              </div>
                            ))
                        : null}
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Snippet

export const pageQuery = graphql`
  {
    sections: allAirtable(
      filter: { table: { eq: "category" }, data: { publish: { eq: 1 } } }
    ) {
      edges {
        node {
          id
          data {
            catid
            publish
            sort
            title
            url
            color
          }
        }
      }
    }
    articles: allAirtable(
      filter: { table: { eq: "article" }, data: { publish: { eq: 1 } } }
    ) {
      edges {
        node {
          id
          data {
            title
            sort
            url
            category
            categorystring
            publish
            featured
            featuredicon
          }
        }
      }
    }
  }
`
